<template>
  <div>
    <van-sticky type="primary">
      <head-bar :title="'日程详情'" @onClickLeft="onClickLeft" />
    </van-sticky>
  <div class="live-page">
    <a class="button-item" :href="fengUrl">
      前往峰会直播
      <img src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202203151743393458326.png" alt="">
      <img src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202203151743393458326.png" alt="">
    </a>
    <a class="button-item" :href="photoUrl">
      前往照片直播
      <img src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202203151743393458326.png" alt="">
      <img src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202203151743393458326.png" alt="">
    </a>
  </div>
  </div>
</template>

<script>
import { Dialog } from 'vant';
import headBar from "@/components/headBar/headBar.vue";
export default {
  name:'LivePage',
  components: {
    headBar
  },
  data () {
    return {
      menuList:[],
      photoUrl:'',
      fengUrl:''
    }
  },
  mounted() {
    this.getNavList()
  },
  methods: {
    onClickLeft() {
      this.$router.back(-1)
    },
    async getNavList() {
      let result = await this.$http.get(this.$baseURL.index.getMenuList() + '/?miceLink=' + this.$store.state.miceInfo.miceLink, 1)
      if (result.code === 200) {
        result.data.forEach(item  => {
          if(item.name === '峰会直播') {
            this.fengUrl = item.linkUrl
          }else if (item.name === '照片直播') {
            this.photoUrl = item.linkUrl
          }
        })
      } else {
        Dialog.alert({
          title: '提示',
          message: result.message,
        }).then(() => {
          // on close
        })
      }
      console.log(this.menuList);
    }
  }
}
</script>

<style lang="less" scoped>
.live-page {
  width: 100%;
  height: 100vh;
  // background-color: aqua;
  background-image: url('https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202203151735400190134.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .button-item {
    width: 195px;
    height: 76px;
    background-image: url('https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202203151738225049838.png');
    background-size: 100% 100%;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 76px;
    text-align: center;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #FEFEFE;
    img {
      width: 8px;
      height: 14px;
      opacity: .9;
      &:nth-child(2){
        opacity: .4;
      }
    }
  }
}
</style>
