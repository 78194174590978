<template>
  <van-nav-bar
    :style="{ background: $store.state.themeInfo.themeColor, color: '#fff' }"
    :title="title"
    :left-text="leftText"
    :left-arrow="leftArrow"
    @click-left="onClickLeft"
    @click-right="onClickRight"
  >
    <template v-if="right" #right>
      <!-- <van-icon style="color:#fff" :name="right" size="20" /> -->
      <!-- <span class="right">
        {{ vipRight ? '分享' : '个人信息' }}
        <img :src="require('@/assets/images/icon/share-icon.png')" alt="">
      </span> -->
      退出登录
    </template>
  </van-nav-bar>
</template>

<script>
export default {
  name: 'headBar',
  props: {
    title: {
      type: String
    },
    leftText: {
      type: String,
      require: false,
      default: '返回'
    },
    leftArrow: {
      type: Boolean,
      require: false,
      default: true
    },
    right: {
      type: Boolean,
      default: false
    },
    vipRight: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClickLeft() {
      this.$emit('onClickLeft')
    },
    onClickRight() {
      this.$emit('onClickRight')
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar__title{
  color: #fff;
  font-size: 14px;
}
/deep/ .van-nav-bar__text{
  color: #fff;
}
/deep/ .van-nav-bar__arrow{
  color: #fff;
}
/deep/.van-nav-bar__right > .van-icon,
/deep/.van-nav-bar > .van-icon{
  color: #fff!important;
}
.van-hairline--bottom::after{
  border: none;
  display: none !important;
  content: '';
  border-bottom-width: 0 !important
}
.right{
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #fff;
  img{
    margin-left: 7px;
    height: 15px;
    width: 15px;
  }
}
</style>
